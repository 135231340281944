.head-right[data-v-82473af4] {
  position: absolute;
  left: 30%;
  top: 6px;
}
.head-right .el-input[data-v-82473af4] {
  width: 300px;
}
.head-right .el-radio-group[data-v-82473af4] {
  padding-left: 10px;
}
.head-right .history-dialog[data-v-82473af4] {
  width: 350px;
  position: absolute;
  top: 32px;
  padding: 12px;
  background: #fff;
  z-index: 999;
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
}
.head-right .history-dialog .history-content[data-v-82473af4] {
  line-height: 30px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
}
.head-right .history-dialog .history-content[data-v-82473af4]:hover {
  background: #F5F7FA;
}
.head-right .history-dialog .history-content span[data-v-82473af4] {
  font-size: 14px;
  color: #606266;
  padding-left: 12px;
}
.head-right .history-dialog .btns[data-v-82473af4] {
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
}
[data-v-82473af4] .el-input-group__prepend .el-input {
  width: 100px !important;
}
[data-v-82473af4] .el-input-group__prepend .el-input .el-input__inner {
  width: 100px !important;
}
[data-v-82473af4] .input-with-select .el-input__inner {
  width: 240px;
  margin: 0;
}